
import { defineComponent, ref } from "vue";
import { Message, Key } from '@element-plus/icons-vue'
import { constant, loading, formSignIn, rule, disable } from '@/store/stateless';
import Auths from '@/core/services/Callable/Auths';

export default defineComponent({
  name: "sign-in",

  setup() {
    const  resetPassword = ref()

    function resetPass () {
      // Auths.AssignPasswordReset({
      //   open: true,
      //   is_populated: false,
      //   reset: {
      //     email: '',
      //     password: '',
      //     confirm_password: '',
      //     code: '',
      //     app: 'health'
      //   }
      // })
    }

    return {
      resetPass,
      resetPassword,
      Message,
      Key,
      constant,
      loading,
      formSignIn,
      rule,
      disable
    };
  },
});
